export enum PaymentSourceStoreStrategy {
  Save = 'save',
  RemoveOnceCompleted = 'removeOnceCompleted',
  None = 'none',
}

export enum PaymentMethod {
  Card = 'card',
  Credits = 'credits',
  CardCredits = 'card+credits',
  Monthly = 'monthly',
}

export enum StripePaymentMethod {
  Card = 'card',
  Paypal = 'paypal',
  GooglePay = 'google_pay',
  ApplePay = 'apple_pay',
}

export interface PaymentIntentParams {
  methodId: string | undefined;
  gaClientId: string | undefined;
  creditsCount: string | undefined;
  strategy: string | undefined;
  subscribeToNewsletter: boolean;
}

export interface PaymentIntent {
  intent_secret: string;
  method_id: string;
}

export class RedirectError extends Error {
  newLocation: string;

  constructor(newLocation: string) {
    super();
    this.newLocation = newLocation;
  }
}
